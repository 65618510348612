import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import TownCardBox from '@/components/town/section/TownCardsBox';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';
import TownOverview from '@/components/town/section/TownOverview';

const Town: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="私たちの町"
      descriptionData="私たちが50年以上に渡って活動する埼玉県富士見市について、こちらのページで簡単にご紹介させていただきます。"
      OgImageData="Town"
    />
    <FirstView pageTitle="私たちの町" fileName="Town" />
    <TownOverview />
    <TownCardBox />
    <BottomMenu />
  </Layout>
);

export default Town;
