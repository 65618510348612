/* eslint-disable no-irregular-whitespace */
import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TownOverViewBox: {
      padding: 0,
      background: theme.palette.primary.main,
    },
    TownOverViewPara: {
      width: `90%`,
      maxWidth: `1200px`,
      margin: `0 auto`,
      padding: `1em ${theme.spacing(2)}px`,
      color: `#ffffff`,
    },
  }),
);

const TownOverview: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.TownOverViewBox}>
      <Typography className={classes.TownOverViewPara}>
        　埼玉県富士見市は1871年に廃藩置県によって誕生し、令和3年に150周年を迎えました。
        <br />
        　今年1月には、埼玉県朝霞市出身の西前頭筆頭大栄翔関が令和3年初場所で埼玉県出身力士として史上初めての優勝を決めました。そして2月には、埼玉県深谷市の農家に生まれた渋沢栄一の大河ドラマがスタートするなど、埼玉県にとって嬉しいニュースが続きます。
        <br />
        　また、富士見市は令和4年4月10日をもって市制施行50周年を迎えます。このページでは私たちと同い年の富士見市についてご紹介します。
      </Typography>
    </section>
  );
};

export default TownOverview;
