import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import TownCard from '../middle/TownCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TownCardsBox: {
      padding: `1rem 0`,
      marginTop: `-2rem`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 15%, ${theme.palette.background.default} 45%, ${theme.palette.background.default} 100%)`,
    },
    cardsWrapper: {
      maxWidth: `1200px`,
      margin: `2rem auto`,
      width: `90%`,
    },
  }),
);

const TownCardBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.TownCardsBox}>
      <Grid container className={classes.cardsWrapper} spacing={3}>
        <Grid item xs={12} md={6}>
          <TownCard
            cardTitle="アクセス良好！"
            content="　大正時代から交通の要衝であったこの町は、東武東上線の沿線に位置しており、ふじみ野、鶴瀬、みずほ台と3つの駅があります。新宿まで33分と都心へのアクセスは良好。副都心線、東急東横線との直通運転で渋谷、横浜まで乗り換え無しで移動できます。"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TownCard
            cardTitle="暮らしに便利！"
            content="　ららぽーと富士見や多くの商店街があり、買い物スポットも充実しています。町並みは静かで散歩やサイクリングにも最適。子育て支援にも力を入れており、こどものことならなんでも相談できる「子ども未来応援センター」や「ファミリー・サポート・センター」などのサービスや「スマイルナビ」による子育て応援情報の発信なども積極的に行っています。"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TownCard
            cardTitle="豊かな自然と文化の町"
            content="　富士見市は当初、南畑村、鶴瀬村、水谷村、が合併してできた村で、村内どこからでも雄大な姿を望むことができる富士山にちなんで「富士見村」と付けられました。市内には複数の自然公園があり、野外レクリエーションや野鳥観察などを楽しむことができる他、シーズンには美しい花々を楽しむことができます。また、国指定史跡の水子貝塚公園や、県指定旧跡の難波田城公園では自然だけでなく、歴史と文化に触れることもできます。"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TownCard
            cardTitle="法律、税務相談等の公益事業も！"
            content="　富士見市では、普段は触れる機会の少ない特別な手続きに関する相談会も定期的に開催されます。私たち鈴木弘基税理士事務所も公益事業に積極的に参加しております。富士見市民の皆様は、これらの相談会もご活用ください。"
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default TownCardBox;
