import React from 'react';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

type BusinessCardProps = {
  cardTitle: string;
  content: string;
};

const useStyles = makeStyles({
  townPaperTitle: {
    margin: `1rem auto`,
  },
  townPaper: {
    padding: `calc(1rem + 1vw)`,
  },
  itemText: {
    wordBreak: `keep-all`,
  },
});

const TownCard: React.VFC<BusinessCardProps> = ({ cardTitle, content }) => {
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.townPaper}>
      <Typography
        variant="h3"
        component="h2"
        className={classes.townPaperTitle}
      >
        {cardTitle}
      </Typography>
      <Typography>{content}</Typography>
    </Paper>
  );
};

export default TownCard;
